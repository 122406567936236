import Counter from "../components/Counter/Counter";

const HelpPage = () => {
	return (
		<span>
			<h2>Hilfe</h2>
			<Counter />
		</span>
	);
}

export default HelpPage;