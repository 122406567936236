export var UserRole;
(function (UserRole) {
    UserRole[UserRole["Normal"] = 0] = "Normal";
    UserRole[UserRole["Raider"] = 1] = "Raider";
    UserRole[UserRole["Maz"] = 2] = "Maz";
    UserRole[UserRole["Moderator"] = 3] = "Moderator";
    UserRole[UserRole["Admin"] = 4] = "Admin";
})(UserRole || (UserRole = {}));
export var Theme;
(function (Theme) {
    Theme[Theme["Light"] = 0] = "Light";
    Theme[Theme["Dark"] = 1] = "Dark";
    Theme[Theme["OldDark"] = 2] = "OldDark";
})(Theme || (Theme = {}));
export var RaidRole;
(function (RaidRole) {
    RaidRole[RaidRole["Normal"] = 0] = "Normal";
    RaidRole[RaidRole["Lieutenant"] = 1] = "Lieutenant";
    RaidRole[RaidRole["Leader"] = 2] = "Leader";
})(RaidRole || (RaidRole = {}));
